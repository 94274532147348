import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import EmailListForm from "../components/EmailListForm"
import bgimage from '../images/banner-images/promo-banner.jpeg'

class TipPage extends React.Component {
  render(props) {

    return (
      <Layout>
      <SEO title="Snaphints competition" />

       <Banner>
          <Container>
            <h1>Competition Time!</h1>
          </Container>
        </Banner>

      <Main>
      
        <p>The competition is now closed.</p>
      
      </Main>
      
        <EmailListForm />

      </Layout>
    )
  }
}

export default TipPage

const Banner = styled.section`
  background-image: url(${bgimage});
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-bottom: 40px;
`

const Container = styled.div`
  max-width: 800px;
  margin: auto; 
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
  }
`

const Main = styled.div`
  max-width: 800px;
  margin: 60px auto; 
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }

  h2 {
    font-size: 22px;
  }

  .pro-tip {
    background: #3c7db6;
    padding: 20px;
    border: 2px white solid;
  }

  @media(max-width: 768px) {
    padding: 0 20px;
    margin-top: 0;
  }
`